import { Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';
import { AppGuard } from './app.guard';
import { VerificationComponent } from './pages/reset-password/verification/verification.component';
import { PaymentMidpageComponent } from './components/payment-midpage/payment-midpage.component';
import { LayoutModule } from './layout/layout.module';
export const ROUTES: Routes = [
  {
    path: '', canActivate: [AppGuard], loadChildren: './layout/layout.module#LayoutModule'
  },
  {
    path: 'redirecttopayment/:id',component:PaymentMidpageComponent 
  },
  {
    path: 'login', loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: 'partnerLogin', loadChildren: './pages/login/login.module#LoginModule'
  },
  
  {
    path: '', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordModule',
  },
  {
    path: 'error', component: ErrorComponent
  },
  {
    path: '**', component: ErrorComponent
  },
];
