import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Autosize } from 'ng-autosize';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { ErrorComponent } from './pages/error/error.component';
import { AppInterceptor } from './app.interceptor';
import { LoginService } from './pages/login/login.service';
import { AppGuard } from './app.guard';
import { HttpModule } from '@angular/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap';
import { EnvServiceProvider } from '../env.service.provider';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatTooltipModule } from '@angular/material';
// import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

const APP_PROVIDERS = [AppConfig, LoginService, AppGuard];

import {
  MatDatepickerModule,
  MatNativeDateModule,
  DateAdapter,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material";
import { DateFormat } from "./services/date-format";
import { PaymentMidpageComponent } from './components/payment-midpage/payment-midpage.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY"
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "YYYY-MM-DD HH:mm:ss",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, ErrorComponent, Autosize, PaymentMidpageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
    NgbModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule, //popup
    CommonModule,
    MatTooltipModule,
  ],
  entryComponents: [
    //AddCityComponent //New Add for Audit Log 
  ],
  providers: [
    APP_PROVIDERS,
    // { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE]
    // },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    // { provide: MAT_DATE_LOCALE, useValue: {useUtc: true} },
    // { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: DateFormat,
    },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MatDialogRef, //New Obj Add
      useValue: {}
    },
    EnvServiceProvider
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale('en-IN'); // DD/MM/YYYY
    // Date.prototype.getToJSON = function(){
    //     const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
    //     this.setHours(hoursDiff);
    //     return this.toISOString();
    // };
  }
}
