import {AppConfig} from '../../app.config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../../auth/token-storage-service';
import * as alertify from 'alertifyjs';
import { Http } from '@angular/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const BASE_API_URL = '';

const jwt = new JwtHelperService();

@Injectable()
export class LoginService {
  config: any;
  _isFetching: boolean = false;
  _errorMessage: string = '';
  permissionList : any = [];
  menuPermissionList : any = [];
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
    private htttp: Http,
    private router: Router,
    private tokenStorageService:TokenStorageService
  ) {
    this.config = appConfig.getConfig();
    //this.getMenuStructure();
    //this.getRoleOperations();
  }

  get isFetching() {
    return this._isFetching;
  }

  set isFetching(val: boolean) {
    this._isFetching = val;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  set errorMessage(val: string) {
    this._errorMessage = val;
  }

  isAuthenticated() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      return true
    }  else {
      return false;
    }
    // let data = null;
    //
    // // We check if app runs with backend mode
    // if (!this.config.isBackend && token) return true;
    // if (!token) return;
    // const date = new Date().getTime() / 1000;
    // try {
    // data = jwt.decodeToken(token);
    // } catch(e) {
    //   this.router.navigate(['/login']);
    // }
    // if (!data) return;
    // return date < data.exp;
  }


  loginUser(creds) {
    // We check if app runs with backend mode
    if (!this.config.isBackend) {
      this.receiveToken('accessToken');
    } else {
      this.requestLogin();
      if (creds.social) {
        // tslint:disable-next-line
        window.location.href = this.config.baseURLApi + '/user/signin/' + creds.social + (process.env.NODE_ENV === 'production' ? '?app=sing-app/angular' : '');
      } else if (creds.email.length > 0 && creds.password.length > 0) {
        this.http.post('login', creds).subscribe((res: any) => {
          const token = res.token;
          this.receiveToken(token);
        }, err => {
          this.loginError('Something was wrong. Try again');
        });

      } else {
        this.loginError('Something was wrong. Try again');
      }
    }
  }
  //API : /api/v1/acl/getRoleOperations

  public getMenuStructure(){
    const get_url = BASE_API_URL + 'acl/getMenuStructure';
   // this.menuPermissionList=[];
    this.http.get(get_url, { headers: httpOptions.headers }).subscribe((res: any) => {
      this.menuPermissionList = res.data.submenu;
      this.tokenStorageService.setMenuPermission(this.menuPermissionList);
    }, err => {
      this.loginError('Something was wrong. Try again');
    });
  }

  public getRoleOperations(){
    
    const get_url = BASE_API_URL + 'acl/getRoleOperations';
      this.permissionList=[];
    this.http.get(get_url, { headers: httpOptions.headers }).subscribe((res: any) => {
      this.permissionList = res.dataList;
      this.tokenStorageService.setUserRoleOperationPermission(this.permissionList);
    }, err => {
      this.loginError('Something was wrong. Try again');
    });
  }

  receiveToken(token) {
    let user: any = {};
    // We check if app runs with backend mode
    if (this.config.isBackend) {
      user = jwt.decodeToken(token).user;
      delete user.id;
    } else {
      user = {
        email: this.config.auth.email
      };
    }
     localStorage.setItem('accessToken', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.receiveLogin();
  }

  logoutUser() {
    this.tokenStorageService.removeUserRoleOperationPermission();
    this.tokenStorageService.removeMenuPermission();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenexpiredTime');
    localStorage.removeItem('user');
    localStorage.removeItem('wealthHubAccId');
    localStorage.removeItem('memberList');
    localStorage.removeItem('partnerId');
    localStorage.removeItem('AuthUsername');
    localStorage.removeItem('nav-static');
    localStorage.removeItem('advSearchResult');
    localStorage.removeItem('columnPreferences');
  

    

    
    if(localStorage.getItem('partnerFlag')==='true' && localStorage.getItem('userRoles') !== "8") {
      //  this.router.navigate(['/login']);
      //  localStorage.removeItem('login');
       this.router.navigate(['/partnerLogin']);
       localStorage.removeItem('partnerLogin');
      }

    if(localStorage.getItem('partnerFlag')==='false' && localStorage.getItem('userRoles') !== "8") {
       this.router.navigate(['/login']);
       localStorage.removeItem('login');
      // this.router.navigate(['/login']);
      // localStorage.removeItem('login');
      }

      /*Keys Removed*/
    localStorage.removeItem('login');
    localStorage.removeItem('partnerFlag');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('userId');
  
      
    document.cookie = 'accessToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  //   if(localStorage.getItem('login') === '/login'){
  //     this.router.navigate(['/login']);
  //   }
 
  //   if(localStorage.getItem('partnerlogin') === '/partnerLogin'){
  //   this.router.navigate(['/partnerLogin']);
  // }

  }

  loginError(payload) {
    this.isFetching = false;
    this.errorMessage = payload;
  }

  receiveLogin() {
    this.isFetching = false;
    this.errorMessage = '';
    this.router.navigate(['/']);
  }

  requestLogin() {
    this.isFetching = true;
  }

  hasOperationPermission(classId,operationId,accessIdForAllOpreation){
    
    let RoleAdmin = localStorage.getItem('userRoles');
    this.permissionList = [];
    this.permissionList = this.tokenStorageService.getUserRoleOperationPermission();
    if(RoleAdmin === '1'){
      return true;
    }
    if(this.permissionList.length > 0){
      for(let permission of this.permissionList){
       
          let isPersmissionList = permission.operations.filter(item => (item.opid === accessIdForAllOpreation || item.opid === operationId) && item.classid === classId);
          if(isPersmissionList.length != 0){
            return true;
          }
      }
      return false;
    }else {
      alertify.error("Sorry you have not privilege to any operation!");
    }
  }

  hideParentMenu(menuId){
    this.menuPermissionList = [];
    let RoleAdmin = localStorage.getItem('userRoles');
    this.menuPermissionList = this.tokenStorageService.getMenuPermission();
    if(RoleAdmin === '1'){
      return true;
    }
    if(this.menuPermissionList){
      if(this.menuPermissionList.length > 0){
        let filterPersmissionList = this.menuPermissionList.filter(menu => menu.menuid === menuId);
        if(filterPersmissionList.length != 0 && filterPersmissionList !== null){
          return true;
        }
      }else {
        alertify.error("Sorry you have not privilege to access any menu!");
      }
    }
  }

  hideSidebarMenu(menuId,subMenuId){
    let RoleAdmin = localStorage.getItem('userRoles');
    if(RoleAdmin === '1'){
      return true;
    }
    this.menuPermissionList =[];
    this.menuPermissionList = this.tokenStorageService.getMenuPermission();
    if(this.menuPermissionList){
      if(this.menuPermissionList.length > 0){
        let filterPersmissionList = this.menuPermissionList.filter(menu => menu.menuid === menuId);
        if(filterPersmissionList.length > 0){
          let isPersmissionList = filterPersmissionList[0].submenu.filter(item => item.menuid === subMenuId);
          if(isPersmissionList.length != 0){
            return true;
          }
        }
      }else {
        alertify.error("Sorry you have not privilege to access any menu!");
      }
    }
  }

  hideChildSidebarMenu(menuId,subMenuId,childSubMenuId){
    let RoleAdmin = localStorage.getItem('userRoles');
    if(RoleAdmin === '1'){
      return true;
    }
    this.menuPermissionList = [];
   this.menuPermissionList = this.tokenStorageService.getMenuPermission();
   if(this.menuPermissionList.length > 0){
    let filterPersmissionList = this.menuPermissionList.filter(menu => menu.menuid === menuId);
    if(filterPersmissionList.length > 0){
      let isPersmissionList = filterPersmissionList[0].submenu.filter(item => item.menuid === subMenuId);
      if(isPersmissionList.length != 0){
        if(isPersmissionList[0].submenu){
         if(isPersmissionList[0].submenu.length > 0){
           let isPersmissionListChild = isPersmissionList[0].submenu.filter(item => item.menuid === childSubMenuId);
           if(isPersmissionListChild.length != 0){
             return true;
          }
         }
        }
      }
    }
   }else {
    alertify.error("Sorry you have not privilege to access any menu!");
   }
 }

 isLoginSuccess(){
  this.getMenuStructure();
  this.getRoleOperations();

 }

 partnerAmmount(parterId):Observable<any>{
   const get_url=BASE_API_URL+"partner/outstandAmount/"+parterId;
   return this.http.get<any>(get_url);
 }

 getVersionFromServer(){
  let _jsonURL = location.origin + "/assets/version.json";
    return this.htttp.get(_jsonURL);
 }

}
