import {
  Component,
  ElementRef,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfig } from '../app.config';
import { SearchService } from '../services/search.service';
import { DashboardThemes } from './helper/helper.interface';
import { HelperService } from './helper/helper.service';
import { MatTableDataSource } from '@angular/material';
import { NgbActiveModal, NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../auth/token-storage-service';
import { Test } from 'tslint';
import  *  as  version  from  '../layout/sidebar/version.json';
import { LoginService } from '../pages/login/login.service';


declare let jQuery: any;
declare let Hammer: any;

@Component({
  selector: 'app-layout',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './layout.template.html',
})
export class LayoutComponent implements OnInit, OnDestroy {
  @HostBinding('class.nav-static') navStatic: boolean;
  @HostBinding('class.search-static') searchStatic: boolean;
  @HostBinding('class.chat-sidebar-opened1') chatOpened: boolean = false;
  @HostBinding('class.chat-sidebar-opened') searchOpened: boolean = false;
  @HostBinding('class.app') appClass: boolean = true;
  @HostBinding('class.sing-dashboard') singDashboardClass: boolean = true;
  @HostBinding('class.dashboard-light') get dashboardLight() {
    return this.helperService.dashboardTheme === DashboardThemes.LIGHT;
  }
  @HostBinding('class.dashboard-dark') get dashboardDark() {
    return this.helperService.dashboardTheme === DashboardThemes.DARK;
  }
  

  dataSource = new MatTableDataSource([]);
  config: any;
  configFn: any;
  $sidebar: any;
  $searchElem: any;
  el: ElementRef;
  router: Router;
  @ViewChild('spinnerElement', { static: true }) spinnerElement: ElementRef;
  @ViewChild('routerComponent', { static: true }) routerComponent: ElementRef;
  @ViewChild('contentpopup',{static:true}) tokenexpiredRef:ElementRef
  subscriptions: Subscription[] = [];
  searchSettings: [] = [];
  preserveFilter: boolean = false;
  advSearchSetting:any []=[];
  tokenDisabled:boolean=false;
  closeResult = '';
  constructor(
    config: AppConfig,
    el: ElementRef,
    router: Router,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private helperService: HelperService,
    private searchService: SearchService,
    private modalService: NgbModal,
    private tokenservice:TokenStorageService,
    private toaster:ToastrService,
    private loginService: LoginService
  ) {
    this.setAutoRefresh();
    this.el = el;
    this.config = config.getConfig();
    this.configFn = config;
    this.router = router;
    // this.tokenExpired();
  }

  setAutoRefresh(){
    this.loginService.getVersionFromServer().subscribe((res) => {
      let serverVersion = JSON.parse(res['_body']); 
      let localVersion = version;
      localVersion = localVersion['default'];
      console.log('Server Version =>', serverVersion);
      console.log('Local Version =>', localVersion);
      if(serverVersion.version != localVersion.version){
        location.reload();
      }
    });
  }

  // tokenExpired(){
  //  let timeout=Number(localStorage.getItem('tokenexpiredTime'))-120000;
  //  let AuthuserName=localStorage.getItem('AuthUsername');              
  // setTimeout(() => {

  //   if(AuthuserName){
  //     this.open(this.tokenexpiredRef);
  //   }else{
  //     this.modalService.dismissAll();
  //   }
  //   this.popupCloseTimer();
  //   }, timeout);
  // }

  popupCloseTimer(){
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 100000);   
  }

  toggleSidebarListener(state): void {
    const toggleNavigation = state === 'static' ? this.toggleNavigationState : this.toggleNavigationCollapseState;
    toggleNavigation.apply(this);
    localStorage.setItem('nav-static', JSON.stringify(this.navStatic));
  }

  toggleChatListener(): void {
    jQuery(this.el.nativeElement).find('.chat-notification-sing').remove();
    this.chatOpened = !this.chatOpened;

    setTimeout(() => {
      // demo: add class & badge to indicate incoming messages from contact
      // .js-notification-added ensures notification added only once
      jQuery('.chat-sidebar-user-group:first-of-type ' + '.list-group-item:first-child:not(.js-notification-added)')
        .addClass('active js-notification-added')
        .find('.fa-circle')
        .before('<span class="badge badge-danger badge-pill ' + 'float-right animated bounceInDown">3</span>');
    }, 1000);
  }

  toggleSearchListener(): void {
    this.searchOpened = !this.searchOpened;
  }

  checkSearchState() {
    const searchState = localStorage.getItem('search-static');
    this.searchStatic = searchState && searchState == 'true' ? true : false;
  }

  toggleSearchBarListener(state): void {
    if (state === 'static') {
      this.toggleSearchState();
    } else {
      this.searchStatic = false;
    }
    localStorage.setItem('search-static', JSON.stringify(this.searchStatic));
  }

  toggleNavigationState(): void {
    this.navStatic = !this.navStatic;
    if (!this.navStatic) {
      this.collapseNavigation();
    }
  }

  expandNavigation(): void {
    // this method only makes sense for non-static navigation state
    if (this.isNavigationStatic() && (this.configFn.isScreen('lg') || this.configFn.isScreen('xl'))) {
      return;
    }
    jQuery('app-layout').removeClass('nav-collapsed');
    console.log('q-->',this.$sidebar);
    
    this.$sidebar
      .find('.active .active')
      .closest('.collapse')
      .collapse('show')
      .siblings('[data-toggle=collapse]')
      .removeClass('collapsed');
  }

  collapseNavigation(): void {
    // this method only makes sense for non-static navigation state
    if (this.isNavigationStatic() && (this.configFn.isScreen('lg') || this.configFn.isScreen('xl'))) {
      return;
    }

    jQuery('app-layout').addClass('nav-collapsed');
    this.$sidebar.find('.collapse.in').collapse('hide').siblings('[data-toggle=collapse]').addClass('collapsed');
  }

  /**
   * Check and set navigation collapse according to screen size and navigation state
   */

  checkNavigationState(): void {
    if (this.isNavigationStatic()) {
      if (this.configFn.isScreen('sm') || this.configFn.isScreen('xs') || this.configFn.isScreen('md')) {
        this.collapseNavigation();
      }
    } else {
      if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
        setTimeout(() => {
          this.collapseNavigation();
        }, this.config.settings.navCollapseTimeout);
      } else {
        this.collapseNavigation();
      }
    }
  }

  isNavigationStatic(): boolean {
    return this.navStatic === true;
  }

  toggleNavigationCollapseState(): void {
    if (jQuery('app-layout').is('.nav-collapsed')) {
      this.expandNavigation();
    } else {
      this.collapseNavigation();
    }
  }

  _sidebarMouseEnter(): void {
    if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
      this.expandNavigation();
    }
  }
  _sidebarMouseLeave(): void {
    if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
      this.collapseNavigation();
    }
  }

  isSearchStatic(): boolean {
    return this.searchStatic === true;
  }

  toggleSearchState(): void {
    this.searchStatic = !this.searchStatic;
  }

  enableSwipeCollapsing(): void {
    const swipe = new Hammer(document.getElementById('content-wrap'));
    const d = this;

    swipe.on('swipeleft', () => {
      setTimeout(() => {
        if (d.configFn.isScreen('md')) {
          return;
        }

        if (!jQuery('app-layout').is('.nav-collapsed')) {
          d.collapseNavigation();
        }
      });
    });

    swipe.on('swiperight', () => {
      if (d.configFn.isScreen('md')) {
        return;
      }

      if (jQuery('app-layout').is('.chat-sidebar-opened')) {
        return;
      }

      if (jQuery('app-layout').is('.nav-collapsed')) {
        d.expandNavigation();
      }
    });
  }

  collapseNavIfSmallScreen(): void {
    if (this.configFn.isScreen('xs') || this.configFn.isScreen('sm') || this.configFn.isScreen('md')) {
      this.collapseNavigation();
    }
  }

  ngOnInit(): void {
    const searchSubscription = this.searchService.openSearchWithSettings().subscribe((obj) => {
      if (obj) {
        this.searchSettings = obj;
        this.toggleSearchListener();
        if (this.preserveFilter == false) {
          this.preserveFilter = true;
        }
        return;
      } else {
        this.searchOpened = true;
        this.toggleSearchListener();
        this.preserveFilter = false;
        this.removeFilter(null);
        return;
      }
    });

    if (localStorage.getItem('nav-static') === 'true') {
      this.navStatic = true;
    }

    const $el = jQuery(this.el.nativeElement);
    this.$sidebar = $el.find('app-sidebar');

    $el.find('a[href="#"]').on('click', (e) => {
      e.preventDefault();
    });

    this.$searchElem = $el.find('app-advance-search');
    $el.on('click', (event) => {
      // event.stopPropagation();
      if (!this.isSearchStatic()) {
        if (
          this.searchOpened &&
          !hasClass(event.target, 'neo-search-btn') &&
          !hasParent(event.target, 'neo-search-btn') &&
          !hasClass(event.target, 'ng-option') &&
          !hasParent(event.target, 'ng-option') &&
          !hasClass(event.target, 'ng-option-label') &&
          !hasClass(event.target, 'la-minus') &&
          !hasParent(event.target, 'custom-mat-page') &&
          !hasParent(event.target, 'custom-neo-table')
        ) {
          if (this.$searchElem.has(event.target).length == 0 && !this.$searchElem.is(event.target)) {
            if (hasParent(event.target, 'search-sidebar-content') && !hasClass(event.target, 'la-times-circle')) return;
            this.toggleSearchListener();
          }
        }
      } else {
        if (hasParent(event.target, 'search-sidebar-content') && hasClass(event.target, 'la-times-circle')) {
          this.toggleSearchListener();
        }
      }
      return;
    });

    this.$sidebar.on('mouseenter', this._sidebarMouseEnter.bind(this));
    this.$sidebar.on('mouseleave', this._sidebarMouseLeave.bind(this));

    this.checkNavigationState();
    this.checkSearchState();

    this.$sidebar.on('click', () => {
      if (jQuery('app-layout').is('.nav-collapsed')) {
        this.expandNavigation();
      }
    });

    const hasClass = (element, className) => {
      return element.classList.contains(className);
    };

    const hasParent = (element, className) => {
      if (!element.parentNode) {
        return false;
      }

      if (hasClass(element, className)) {
        return true;
      }

      return hasParent(element.parentNode, className);
    };

    this.router.events.subscribe((event) => {
      this._navigationInterceptor(event);
      this.collapseNavIfSmallScreen();
      window.scrollTo(0, 0);
      if (event instanceof NavigationEnd) {
        this.searchService.resetFilter(null);
      }
    });

    if ('ontouchstart' in window) {
      this.enableSwipeCollapsing();
    }

    this.$sidebar
      .find('.collapse')
      .on('show.bs.collapse', function (e): void {
        // execute only if we're actually the .collapse element initiated event
        // return for bubbled events
        if (e.target !== e.currentTarget) {
          return;
        }

        const $triggerLink = jQuery(this).prev('[data-toggle=collapse]');
        jQuery($triggerLink.data('parent')).find('.collapse.show').not(jQuery(this)).collapse('hide');
      })
      /* adding additional classes to navigation link li-parent
     for several purposes. see navigation styles */
      .on('show.bs.collapse', function (e): void {
        // execute only if we're actually the .collapse element initiated event
        // return for bubbled events
        if (e.target !== e.currentTarget) {
          return;
        }

        jQuery(this).closest('li').addClass('open');
      })
      .on('hide.bs.collapse', function (e): void {
        // execute only if we're actually the .collapse element initiated event
        // return for bubbled events
        if (e.target !== e.currentTarget) {
          return;
        }

        jQuery(this).closest('li').removeClass('open');
      });

    this.subscriptions.push(searchSubscription);
  }

  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      // We wanna run this function outside of Angular's zone to
      // bypass change detection
      this.ngZone.runOutsideAngular(() => {
        // For simplicity we are going to turn opacity on / off
        // you could add/remove a class for more advanced styling
        // and enter/leave animation of the spinner
        this.renderer.setStyle(this.spinnerElement.nativeElement, 'opacity', '1');
        this.renderer.setStyle(this.routerComponent.nativeElement, 'opacity', '0');
      });
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
    }

    // Set loading state to false in both of the below events to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  private _hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
    this.ngZone.runOutsideAngular(() => {
      // For simplicity we are going to turn opacity on / off
      // you could add/remove a class for more advanced styling
      // and enter/leave animation of the spinner
      this.renderer.setStyle(this.spinnerElement.nativeElement, 'opacity', '0');
      this.renderer.setStyle(this.routerComponent.nativeElement, 'opacity', '1');
    });
  }

  setFilter(event) {
    if (event) {
       this.searchService.setFilterOptions(event);
       //this.toggleChatListener();
    }
  }
  
  removeFilter(event) {
    this.searchService.clearFilterOptions(event);
  }



  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }


  open(content) {

  this.modalService.open(content,
   {ariaLabelledBy: 'modal-basic-title',backdrop: 'static', keyboard: false},
      ).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
          
      }, (reason) => {
        this.closeResult = 
        `Dismissed ${this.getDismissReason(reason)}`;
        
    
    });
  }

  public getDismissReason(reason: any): string {
      
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
 
 submitToken(){
   this.tokenDisabled=true;
   this.tokenservice.getRefrehToken().subscribe(res=>{
          if(res.status===200){
              localStorage.removeItem('accessToken');
              localStorage.removeItem('tokenexpiredTime');
              localStorage.setItem('accessToken',res.accessToken);
              localStorage.setItem('tokenexpiredTime',res.tokentimeout);
              this.tokenDisabled=false;
              
              this.modalService.dismissAll()
            } else if(res.status !==200){
              this.tokenDisabled=true;
              localStorage.removeItem('accessToken');
              localStorage.removeItem('tokenexpiredTime');
              this.modalService.dismissAll()
            }
            this.setAutoRefresh();
    } ,
     (error)=>{
            this.tokenDisabled=true;
            localStorage.removeItem('accessToken');
            localStorage.removeItem('tokenexpiredTime');
            this.modalService.dismissAll()
  }
  )
}
}