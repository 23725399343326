import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from './service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ResponseCode } from '../../core/constant/response-code';

@Component({
  selector: 'app-payment-midpage',
  templateUrl: './payment-midpage.component.html',
  styleUrls: ['./payment-midpage.component.scss']
})
export class PaymentMidpageComponent implements OnInit ,OnDestroy{
  @ViewChild('hiddenPayuForm',{static: false}) hiddenPayuFormElement;
  timeLeft: number = 5;
  time:number =5
  redirectotUrl:any
  subscribeTimer: any;
  interval;
  uniqueId:any
  paymentGatewayFormGroup:FormGroup;
  public payuformSubmitUrl:string = '';
  urlcallflag:boolean=true
  paymentStatus:string;
  pgResStatus:string;
  formTemplate:any = [];
  customerLogo :any
  transId:any;
  orderCheck:any = false;
  constructor(
    private router : Router,
    private route : ActivatedRoute,
    private paymentService : ServiceService
  ) { }

  ngOnInit() {
    if(!localStorage.getItem('reload')){
      location.reload();
      localStorage.setItem('reload','false');
    }
    this.startTimer()
   
    
    }
 
  getDataByTransaction(transId:any){
this.paymentService.getDataByTransId(transId).subscribe((res)=>{

  this.uniqueId = res.data.uniqueId;
  this.redirectotUrl = res.data.submitUrl
  console.log("result1::",res)
  
  this.pgResStatus = res.data.pgResStatus
  this.paymentStatus = res.data.paymentStatus;
  if(res.data.paymentStatus == 'Pending' && !res.data.pgResStatus ){
    this.createDynamicFormByResponse(res);
  }
  else{
    this.paymentStatus = res.data.paymentStatus;
    setTimeout(() => {
      this.router.navigate(['/dashboard'])
    }, 3000);
  
  }
  
  console.log("result::",this.uniqueId)
})
  }

  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {
      console.log(val, '-');
      this.subscribeTimer = this.timeLeft - val;
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } 
      if(this.timeLeft ===  0 && this.urlcallflag === false){
        return;
      }
    },1000)

  }


  createDynamicFormByResponse(res:any){

    this.payuformSubmitUrl = res.data.submitUrl;

    let formGroup = {};
    for (let key in res.data.pgDetails) {
      let formControl = {name:'',value:''};
      if (res.data.pgDetails.hasOwnProperty(key)) {
        formControl.name = key;
        formControl.value = res.data.pgDetails[key];
        this.formTemplate.push(formControl);
      }
    }

    this.formTemplate.forEach( obj => {
      formGroup[obj.name] = new FormControl(obj.value);
    });

   this.paymentGatewayFormGroup = new FormGroup(formGroup);

   if(this.payuformSubmitUrl !=='') {
    this.paymentService.checkOrder(this.transId).subscribe((res)=>{
       
      if(res.responseCode === ResponseCode.OK){
        this.executePayu();
      }
      else{
       this.orderCheck = true
      }
     });
   }
}

executePayu() {
  setTimeout(() => {
    console.log('this.hiddenPayuFormElement',this.hiddenPayuFormElement);
    this.hiddenPayuFormElement.nativeElement.submit();
  },1000);
}
onClickexecutePayu() {
    this.hiddenPayuFormElement.nativeElement.submit();
}
redirectToDashboard(){
  this.router.navigate(['/dashboard'])
}
ngOnDestroy(): void {
  this.orderCheck = false
}
}
