import { Injectable }  from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  *  as  version  from  '../layout/sidebar/version.json';


const TOKEN_KEY = "accessToken"
const USERNAME_KEY = "AuthUsername";
const AUTHORITIES_KEY = "AuthAuthorities"
const USER_ID = "userId"
const PARTNER_ID = "partnerId"
const ISPARTNERFLAG="partnerFlag"
const USER_PERMISSION_LIST = "permissionList"
const USER_ROLE="userRoles"
const VERSION_NO="versionNo"
const USER_MENU_PERMISSION_LIST = "menuPermissionList"
const TOKEN_EXPIRED_TIME="tokenexpiredTime"



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private roles: Array<string> = [];
  constructor(private httpClient: HttpClient) { }

  signOut() {
    this.removeAllCookies();
    window.localStorage.clear();
  }

  public saveToken(token: string) {
    this.removeCookie(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  

  public getToken(): string {
    // return this.getCookie(TOKEN_KEY);
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public saveUserName(userName: string) {
    window.localStorage.removeItem(USERNAME_KEY);
    window.localStorage.setItem(USERNAME_KEY, userName);
  }

  public saveUserId(userId: string) {
    window.localStorage.removeItem(USER_ID);
    window.localStorage.setItem(USER_ID, userId);
  }

  public savePartnerId(partnerId: string) {
    window.localStorage.removeItem(PARTNER_ID);
    window.localStorage.setItem(PARTNER_ID, partnerId);
  }
  public IspartnerFlag(partnerFlag:string){
    localStorage.setItem(ISPARTNERFLAG,partnerFlag)
  }
  public getUserRole(userRoles:string){
    localStorage.setItem(USER_ROLE,userRoles)
  }

  public getUserName(): string {
    return window.localStorage.getItem(USERNAME_KEY);
  }

  public getPartnerId(): string {
      return window.localStorage.getItem(PARTNER_ID);
  }


  setCookie(name,value,seconds) {
      var expires = "";
      if (seconds) {
          var date = new Date();
          date.setSeconds(date.getSeconds() + seconds);
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
  getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }
  removeCookie(name) {
      document.cookie = name+'=; Max-Age=-99999999;';
  }
  removeAllCookies() {
    var res = document.cookie;
    var multiple = res.split(";");
    for(var i = 0; i < multiple.length; i++) {
       var key = multiple[i].split("=");
       this.removeCookie(key[0]);
    }
  }
  public getAuthorities(): string[] {
    this.roles = [];

    if (window.localStorage.getItem(AUTHORITIES_KEY) == null) {
      return this.roles;
    }

    JSON.parse(window.localStorage.getItem(AUTHORITIES_KEY)).forEach(authority => {
      this.roles.push(authority.authority);
    });

    return this.roles;
  }

  public setUserRoleOperationPermission(permissionList){
    window.localStorage.setItem(USER_PERMISSION_LIST, JSON.stringify(permissionList));
  }

  public getUserRoleOperationPermission(){
    return JSON.parse(window.localStorage.getItem(USER_PERMISSION_LIST));
  }

  public removeUserRoleOperationPermission() {
    window.localStorage.removeItem(USER_PERMISSION_LIST);
  }

  public setMenuPermission(permissionList){
    window.localStorage.setItem(USER_MENU_PERMISSION_LIST, JSON.stringify(permissionList));
  }

  public getMenuPermission(){
    return JSON.parse(window.localStorage.getItem(USER_MENU_PERMISSION_LIST));
  }

  public removeMenuPermission() {
    window.localStorage.removeItem(USER_MENU_PERMISSION_LIST);
  }


  //adminPortalJwtRefresh
  getRefrehToken():Observable<any>{
    const get_url = BASE_API_URL + 'staff';
     return this.httpClient.post<any>(get_url, { headers: httpOptions.headers });
  }

}
